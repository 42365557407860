import styled from "styled-components"

export const UnderlineHeading = styled.h2`
  font-weight: 500;
  margin-top: 35px;
  display: inline-block;
  position: relative;
  margin-bottom: 50px;
  &::after {
    content: " ";
    height: 2px;
    background-color: ${({ theme }) => theme.red};
    width: 40%;
    position: absolute;
    left: 0;
    bottom: -10px;
  }
`
