import React from "react"
import styled from "styled-components"

export const ContactBox = styled.div`
  margin-top: 30px;
  padding: 15px;
  border: 1px solid rgb(223, 223, 223);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  font-size: 14px;
  text-align: center;
  h4 {
    font-size: 15px;
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
  }
`
