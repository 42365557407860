import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    margin: 0;
    margin-top: 40px;
    padding: 0;
    width: 100%;
    list-style: none;
    li {
      border-bottom: 1px solid rgb(237, 237, 222);

      transition: 0.2s background-color;
      font-size: 14px;
      &:hover {
        background-color: rgb(238, 238, 238);
      }
      a {
        padding: 5px 10px;
        position: relative;
        display: block;
        text-decoration: none;
        color: rgb(102, 102, 102);
        &.active {
          background-color: rgb(245, 245, 245);
          font-weight: bolder;
        }
      }
      a::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 4px solid rgba(0, 0, 0, 0);
        border-bottom: 4px solid rgba(0, 0, 0, 0);
        border-left: 4px solid rgb(51, 51, 51);
        margin: 7px 0 0 -9px;
        position: absolute;
      }
    }
  }
`
export const Sidebar = ({ children }) => <Wrapper>{children}</Wrapper>
