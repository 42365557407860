import React from "react"
import Layout from "../components/layout"
import { PageHeader } from "../components/PageHeader"
import SEO from "../components/seo"
import styled from "styled-components"
import PortableText from "@sanity/block-content-to-react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import { HeroImg } from "../components/HeroImg"
import { UnderlineHeading as Heading } from "../components/UnderlineHeading"
import imageUrlBuilder from "@sanity/image-url"
import { Sidebar } from "../components/Sidebar"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { ContactBox } from "../components/ContactBox"
const urlFor = source =>
  imageUrlBuilder({
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
  }).image(source)

const ImgGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px; */
  & > div:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 20px;
  }
  img {
    cursor: pointer;
  }
`
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: space;
  width: 100%;
  justify-content: space-between;
`
const PageWrapper = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px;
  margin: 0 auto;
  padding: 20px;
  @media (max-width: 862px) {
    grid-template-columns: 1fr;
  }
`

const serializers = {
  types: {
    logos: props => (
      <FlexWrapper>
        {props.node.logo.map((item, index) => (
          <img
            key={index}
            src={urlFor(item.asset)
              .width(100)
              .quality(80)
              .url()}
          />
        ))}
      </FlexWrapper>
    ),
  },
  listItem: props => (
    <li>
      <StyledIcon icon={faCheck} />
      {props.children}
    </li>
  ),
  marks: {
    link: props => (
      <StyledLink to={props.mark.href}>
        {props.children}
        <FontAwesomeIcon icon={faArrowRight} />
      </StyledLink>
    ),
  },
}
const StyledLink = styled(Link)`
  margin-top: 30px;
  font-size: 18px;
  color: ${({ theme }) => theme.darkBlue};
  line-height: 20px;
  position: relative;
  border-bottom: 2px solid ${({ theme }) => theme.red};
  svg {
    margin-left: 5px;
    color: ${({ theme }) => theme.red};
    transition: 0.3s margin;
  }
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.darkBlue};
    svg {
      margin-left: 10px;
    }
  }
`
const PageContent = styled.div`
  font-size: 16px;
  ul {
    margin: 0;
    list-style: none;
    margin-bottom: 20px;
  }
  padding-bottom: 60px;
`
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${({ theme }) => theme.red};
  margin-right: 10px;
`
const StyledH3 = styled.h3`
  margin: 25px 0;
`

const PageTemplate = ({ pageContext: { data } }) => {
  return (
    <Layout>
      <SEO title={data.title} description={data._rawSeo.meta_description} />
      <PageHeader>{data.title}</PageHeader>
      <PageWrapper>
        <Sidebar>
          <ul>
            <li>
              <Link activeClassName="active" to="/serwis-olejowy">
                Serwis olejowy
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/serwis-motocykli">
                Serwis motocykli
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/mechanika-samochodowa">
                Mechanika samochodowa
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/obsluga-klimatyzacji">
                Obsługa klimatyzacji
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/oleje-filtry-akcesoria">
                Akcesoria samochodowe
              </Link>
            </li>
          </ul>
          <ContactBox>
            <h4>Zadzwoń i zapytaj o szczegóły</h4>
            <span>
              Telefon:<a href="tel:+123863336"> 12 386 33 36 &nbsp;</a>
            </span>
          </ContactBox>
        </Sidebar>
        <PageContent>
          <Heading>{data.title}</Heading>
          <PortableText blocks={data._rawText} serializers={serializers} />
          <SimpleReactLightbox enablePanzoom={false}>
            <SRLWrapper>
              {data.gallery.length > 0 && <StyledH3>Galeria</StyledH3>}
              <ImgGrid>
                {data.gallery.map((item, index) => (
                  <div>
                    <HeroImg
                      style={{ width: 250 }}
                      fluid={item.asset.fluid}
                      key={index}
                      alt={item.alt}
                    />
                  </div>
                ))}
              </ImgGrid>
            </SRLWrapper>
          </SimpleReactLightbox>
        </PageContent>
      </PageWrapper>
    </Layout>
  )
}
export default PageTemplate
